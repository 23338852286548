import {Injectable} from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadOne, LoadOneSuccess, ProjectRoomActionTypes} from './project-room.actions';
import * as ProcessActions from './../process/process.actions';
import * as ProcessParticipantActions from './../process-participant/process-participant.actions';
import {ProjectRoom} from './project-room';
import {ProjectRoomType} from './project-room.interface';
import {environment} from '../../../environments/environment';

@Injectable()
export class ProjectRoomEffects {
  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(ProjectRoomActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      if (!environment.production) {
        // TODO: Remove after participant debugging
        console.error('Participants Loaded by Effect ProjectRoomActionTypes.LoadOne');
      }
      const projectRoom = new ProjectRoom(action.id, ProjectRoomType.Default);
      const actions: any[] = [
        new LoadOneSuccess(projectRoom),
        new ProcessActions.LoadOne(action.id),
        // This IAM activity call is deprecated in favor of the IAM Policy Manager.
        // new IamProcessActivityActions.LoadAll(action.id),

        // The milestone API is unused at processes. The GANTT chart was removed from PR's header.
        // new MilestoneActions.LoadAll(action.id),
        // new ProcessArtifactActions.LoadAll(action.id, action.options.recursive)
      ];

      if (!action.options.skipParticipants) {
        actions.push(new ProcessParticipantActions.LoadAll(action.id));
      }

      return actions;
    })
  ));

  constructor(private actions: Actions) {
  }
}




