import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Subject} from 'rxjs/internal/Subject';
import {DocumentPreviewDocument} from 'app/+store/document-preview-document/document-preview-document';
import {DocumentPreviewDocumentActions, DocumentPreviewDocumentSelectors} from 'app/+store/document-preview-document';
import {DocumentPreviewPreview} from 'app/+store/document-preview-preview/document-preview-preview';
import {DocumentPreviewPreviewState} from 'app/+store/document-preview-preview/document-preview-preview.interface';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {ProcessArtifactActions, ProcessArtifactSelectors} from 'app/+store/process-artifact';
import {Net} from 'app/lib/fivef-net/uuid';
import {ProcessArtifactService} from 'app/+store/process-artifact/process-artifact.service';
import {AngularTokenService} from 'angular-token';
import {DmsPdfExportService} from 'app/+store/_legacy/api/services/dms-pdf-export.service';
import {DocumentPreview} from 'app/+store/document-signature/document-signature';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {FeatureSelectors, ItemLabelsActions, OrganizationSelectors} from 'app/+store';
import {Organization} from 'app/+store/organization/organization';
import {Feature} from 'app/+store/feature/feature';
import {DocumentSignatureSelectionMenuViewType, FivefSignatureSelectionComponent} from '../../../../../../lib/fivef-ui/sign/fivef-signature-selection/fivef-signature-selection.component';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import * as api from '../../../../../../+store/iam/process-policy.interface';
import {ProcessService} from '../../../../../../+store/process/process.service';

/**
 * Previewer modes: Single preview of document or thumbnail browser.
 */
export enum PreviewBrowserViewmode {
  Document = 'document',
  Imagelist = 'imagelist'
}

/**
 * Toggle of preview browser between fast images and PDF previewer.
 */
export enum DocumentViewMode {
  Image = 'Image',
  PDF = 'PDF'
}

@Component({
  selector: 'fivef-artifact-preview-browser',
  host: {'class': 'fivef-preview-browser'},
  templateUrl: './fivef-artifact-preview-browser.component.html',
  styleUrls: ['./fivef-artifact-preview-browser.component.scss'],
})
export class FivefArtifactPreviewBrowserComponent implements OnChanges, OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public policy$: Observable<api.Iam.IProcessPolicy>;

  public DocumentViewMode = DocumentViewMode;
  public DocumentSignatureSelectionMenuViewType = DocumentSignatureSelectionMenuViewType;

  @Input()
  public enableTaskCreation = true;

  @Input()
  private startOpen: boolean = true;

  @Input()
  isMember = false;

  public isOpen: boolean = true;

  @Input()
  public viewMode: PreviewBrowserViewmode = PreviewBrowserViewmode.Document;

  public _documentIds: string[] = [];

  public listDocuments$: Observable<DocumentPreviewDocument[]>;

  private _documentIds$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private processId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private viewDocumentId$: BehaviorSubject<string> = new BehaviorSubject(null);

  @Input()
  lockedProcess = true;

  public _processId: string;

  /**
   * Toggle single preview mode and file browser.
   */
  @Input()
  documentViewMode = DocumentViewMode.Image;

  @Output() onCreateTask = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() updateDialogueScreen = new EventEmitter();

  pdfLoading = true;
  pdfIsAvailable = true;
  pdf$ = new BehaviorSubject<DocumentPreview>(null);

  organization$: Observable<Organization>;
  public viewDocument$: Observable<DocumentPreviewDocument>;
  public currentProcessArtifactId$ = new BehaviorSubject<string>(null);
  private currentProcessArtifact$ = new BehaviorSubject<DocumentPreviewDocument>(null);
  public currentProcessArtifact: ProcessArtifact;

  @Input() selectedDocumentPreviewTab = 0;
  featureSet$: Observable<Feature>;
  public documents$: Observable<DocumentPreviewDocument[]>;
  loading$: Observable<boolean>;

  public fullScreen: boolean = false;

  @Input() set document(doc: ProcessArtifact) {
    if (doc) {
      this.currentProcessArtifact = doc;
    }
  }

  @Input()
  public set documentIds(ids: string[]) {
    this._documentIds = ids;
    this._documentIds$.next(ids);
    this.updateDocuments();
  }

  @Input()
  private set documentId(documentId: string) {
    if (documentId) {
      this.openDocument(documentId);
    }
  }

  @Input()
  private set processId(pid: string) {
    this._processId = pid;
    this.processId$.next(pid);
  }

  constructor(private _store: Store<AppState>,
              private processSvc: ProcessService,
              private _artifactSvc: ProcessArtifactService,
              private _pdfSvc: DmsPdfExportService,
              private _signSvc: DocumentSignatureService,
              private _tokenSvc: AngularTokenService,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone) {
  }

  fullScreenDialogue(value) {
    this.updateDialogueScreen.emit(value);
    this.fullScreen = value;
  }

  ngOnInit() {
    this.documents$ = this._documentIds$
      .pipe(switchMap(ids => this._store.select(DocumentPreviewDocumentSelectors.documentsByIds(ids))));

    this.policy$ = this.processId$.pipe(switchMap(pid => this.processSvc.myIamProcessPolicy(pid)));

    // const viewDocumentId = this.viewDocumentId || null;
    // this.currentProcessArtifactId$.next(viewDocumentId);

    this.viewDocument$ = combineLatest(this.viewDocumentId$, this.documents$)
      .pipe(
        map(([viewDocumentId, docs]: [string, DocumentPreviewDocument[]]) => {
          let document = null;
          this.currentProcessArtifactId$.next(viewDocumentId);

          if (!viewDocumentId && docs.length === 0) {
            return null;
          }

          if (!viewDocumentId && docs.length > 0) {
            document = docs[0];
          }

          document = document || docs.find((doc) => doc.id === viewDocumentId);

          // If document is found and valid run mark seen call to remove new indicator.
          if (document) {
            this.currentProcessArtifact$.next(document);
          }
          return document || this.getNotFoundDocument(viewDocumentId);
        })
      );

    this.listDocuments$ = this.documents$
      .pipe(
        map((docs: DocumentPreviewDocument[]) => {
          const res: DocumentPreviewDocument[] = [];
          this._documentIds.forEach((id: string) =>
            res.push(
              docs.find((doc) => doc.id === id) || this.getNotFoundDocument(id)
            )
          );
          return res;
        }));

    const smallScreen = window.innerWidth < 1200 ? true : false;
    if (smallScreen) {
      this.fullScreenDialogue(true);
    } else {
      this.fullScreenDialogue(false);
    }

    this.organization$ = this._store
      .select(OrganizationSelectors.getSelected)
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy));

    this.isOpen = this.startOpen;
    this.loading$ = this._store.select(
      DocumentPreviewDocumentSelectors.loadingState
    );

    this.currentProcessArtifactId$.pipe(
      switchMap((id) => {
        if (!Net.validUUID(id)) return of(null);
        return this._store.select(ProcessArtifactSelectors.getProcessArtifactById(id));
      }),
      takeUntil(this.onDestroy)
    ).subscribe(artifact => {
      if (artifact) {
        this.currentProcessArtifact = artifact;
      }
      this._cdr.detectChanges();
    });

    combineLatest(this.organization$)
      .pipe(takeUntil(this.onDestroy)).subscribe(([organization]) => {
      if (organization) {
        this._documentIds$
          .pipe(
            filter((ids) => !!ids),
            distinctUntilChanged(),
            takeUntil(this.onDestroy)
          )
          .subscribe((documentsId) => {
            this._ngZone.runOutsideAngular(() => {
              this._store.dispatch(new ItemLabelsActions.LoadAll(documentsId));
            });
          });
      }
    });

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);

    // Mark seen: Listens to current viewed document and marks it read.
    // If document is found and valid run mark seen call to remove new indicator.
    this.currentProcessArtifact$
      .pipe(filter(a => !!a), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe(artifact => {
        this._ngZone.runOutsideAngular(_ => {
          this._store.dispatch(new ProcessArtifactActions.MarkSeen(artifact.id));
        });
      });

    // Label setup for current view document.

  }

  ngOnDestroy() {
    this._documentIds$.complete();
    this.pdf$.complete();
    this.currentProcessArtifactId$.complete();
    this.currentProcessArtifact$.complete();
    this.viewDocumentId$.complete();
    this.processId$.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  public open(document_id: string = null): void {
    if (document_id !== null) {
      this.openDocument(document_id);
      return;
    }

    if (this._documentIds.length === 0) {
      return;
    }

    this.openDocument(this._documentIds[0]);
  }

  public openDocument(document_id: string): void {
    this.isOpen = true;
    this.viewMode = PreviewBrowserViewmode.Document;
    this.loadPDFPreview(document_id);
    this.viewDocumentId$.next(document_id);
    this.currentProcessArtifactId$.next(document_id);

    this._store.select(ProcessArtifactSelectors.getProcessArtifactById(document_id))
      .pipe(filter(a => !!a), first(), takeUntil(this.onDestroy)).subscribe(processArtifact => {
      if (processArtifact && document_id === processArtifact.id) {
        this.currentProcessArtifact = processArtifact;
      }
    })
    this._cdr.detectChanges();
  }

  public close(): void {
    this.isOpen = false;
  }

  public openNextDocument(document_id: string = null): void {
    if (!document_id) {
      document_id = this.viewDocumentId$.value;
    }
    this.openDocument(this.getNextDocumentId(document_id));
  }

  public openPreviousDocument(document_id: string = null): void {
    if (!document_id) {
      document_id = this.viewDocumentId$.value;
    }
    this.openDocument(this.getPreviousDocumentId(document_id));
  }

  public openImagelist(): void {
    this.viewMode = PreviewBrowserViewmode.Imagelist;
  }

  public onImagelistItemClicked($event) {
    this.openDocument($event.id);
  }

  public closeImagelist(): void {
    this.viewMode = PreviewBrowserViewmode.Document;
  }

  private getNextDocumentId(document_id: string = null): string {
    if (!document_id) {
      document_id = this.viewDocumentId$.value;
    }
    const index: number = this._documentIds.indexOf(document_id);
    if (index < 0) {
      return this._documentIds[0];
    }
    if (index === this._documentIds.length - 1) {
      return this._documentIds[0];
    }
    return this._documentIds[index + 1];
  }

  private getPreviousDocumentId(document_id: string = null): string {
    if (!document_id) {
      document_id = this.viewDocumentId$.value;
    }
    const index: number = this._documentIds.indexOf(document_id);
    if (index < 0) {
      return this._documentIds[0];
    }
    if (index === 0) {
      return this._documentIds[this._documentIds.length - 1];
    }
    return this._documentIds[index - 1];
  }

  private updateDocuments(): void {
    const ids: string[] = this._documentIds;
    this._store.dispatch(new DocumentPreviewDocumentActions.RequestDocuments(ids));
  }

  private getNotFoundDocument(
    document_id: string = null
  ): DocumentPreviewDocument {
    return new DocumentPreviewDocument(
      document_id,
      0,
      'Document Not Found',
      '',
      null,
      []
    );
  }

  public isNotFoundDocument(document: DocumentPreviewDocument): boolean {
    return (
      document.pages === 0 &&
      document.displayName === 'Document Not Found' &&
      document.fileName === '' &&
      document.mimeType === null
    );
  }

  public calculateImageHeightPercent(preview: DocumentPreviewPreview): number {
    if (preview.state !== DocumentPreviewPreviewState.Completed) {
      return 50;
    }
    if (
      !preview.width ||
      !preview.height ||
      preview.width === 0 ||
      preview.height === 0
    ) {
      return 50;
    }

    return (preview.height / preview.width) * 90;
  }

  createTask() {
    if (!this.viewDocumentId$.value || !this.currentProcessArtifact) return;

    const document = this.currentProcessArtifact;
    this.onCreateTask.emit(document);
    this.onClose.emit({close: document.id});
  }

  loadPDF() {
    if (!this.viewDocumentId$.value) return;

    this._pdfSvc.getPdf(this.viewDocumentId$.value);
  }

  download() {
    if (!this.viewDocumentId$.value || !this.currentProcessArtifact) return;

    const document = this.currentProcessArtifact;
    this._artifactSvc.getBlob(`${document.externalUrl}`, document.title, this._tokenSvc.currentAuthData);
  }

  private loadPDFPreview(did: string) {
    this.pdfIsAvailable = true;
    this.pdfLoading = true;
    // this.pdf$.next(null);

    if (this.documentViewMode === DocumentViewMode.Image) return;

    if (Net.validUUID(did)) {
      this._ngZone.runOutsideAngular((_) => {
        this._signSvc
          .getDocumentPreview(did)
          .pipe(first())
          .subscribe(
            (document) => {
              this.pdfIsAvailable = true;
              this.pdfLoading = false;
              this.pdf$.next(document);
              this._cdr.detectChanges();
            },
            (err) => {
              this.pdfIsAvailable = false;
              this.pdfLoading = false;
              this.pdf$.next(null);
              this._cdr.detectChanges();
            }
          );
      });
    }
  }

  switchDocumentViewMode($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.switchToPdfPreview();
    } else {
      this.switchToImagePreview();
    }
  }

  switchToPdfPreview() {
    this.documentViewMode = DocumentViewMode.PDF;
    this.loadPDFPreview(this.viewDocumentId$.value);
    this._cdr.detectChanges();
  }

  switchToImagePreview() {
    this.documentViewMode = DocumentViewMode.Image;
    this._cdr.detectChanges();
  }

  public signatureSupported(artifact): boolean {
    if (!artifact) {
      return false;
    }
    return FivefSignatureSelectionComponent.supportedDocument(artifact);
  }

  /**
   * Public refreshs the artifact on mark read.
   * @param comment
   */
  public refreshArtifact(comment) {
    this._store.dispatch(new ProcessArtifactActions.LoadOneById(comment.backtrackId));
  }
}
